import styles from "../../../../../assets/styles/interview.module.scss";
import { useFetchActorList } from "../../../../../hooks/admin/useFetchActorList";
import { Actor } from "../../../../../hooks/admin/useFetchActorList/type";

export const ActorIntroduction = ({
  actors,
}: {
  actors: Actor["id"][] | undefined;
}) => {
  const { data } = useFetchActorList();
  const filteredActors = data?.filter((actor) => actors?.includes(actor.id));

  return (
    <>
      {filteredActors?.map((actor, index) => (
        <div key={index} className={styles.introduction}>
          <p>
            {actor.name}（{actor.yomiKana}） 声優。
            <br />
            <br />
            {actor.description}
          </p>
        </div>
      ))}
    </>
  );
};
