import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
} from "firebase/firestore";
import type { InterviewList } from "./type";

export const interviewListConverter: FirestoreDataConverter<InterviewList> = {
  toFirestore: (data: InterviewList) => {
    return {
      id: data.id,
      title: data.title,
      thumbnail: data.thumbnail,
      actors: data.actors,
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): InterviewList => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      title: data.title,
      thumbnail: data.thumbnail,
      actors: data.actors,
    };
  },
};
