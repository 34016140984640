import { Button } from "../../components/Button";
import styles from "../../assets/styles/stream.module.scss";
import { useGetOgpImageUrl } from "../../hooks/reactQuery/useGetOgpImageUrl";

interface RelatedVoiceDramaItemProps {
  voiceDramaId: string;
  title: string;
  totalChapterCount: number;
  totalChapterTime: number;
  chapterParam: string;
  totalPrice: number;
}

// MEMO: 通貨のフォーマット処理が他に必要ならutilsに移動
const formatCurrency = (number: number) => {
  return new Intl.NumberFormat("ja-JP").format(number);
};

export const RelatedVoiceDramaItem = ({
  voiceDramaId,
  title,
  totalChapterCount,
  totalChapterTime,
  chapterParam,
  totalPrice,
}: RelatedVoiceDramaItemProps) => {
  const { data: ogpImageUrl } = useGetOgpImageUrl(voiceDramaId);

  const formatTotalChapterTime = `${Math.floor(totalChapterTime / 60)}分${
    totalChapterTime % 60
  }秒`;

  return (
    <>
      <img src={ogpImageUrl} alt="" />
      <div className={styles.modal_relation_footer}>
        <div className={styles.modal_relation_txt}>
          <h3>{title}</h3>
          <p>
            全{totalChapterCount}チャプター / {formatTotalChapterTime}
          </p>
        </div>
        <Button
          className={styles.modal_relation_btn}
          size="s"
          onClick={() =>
            window.open(
              `/purchase/${voiceDramaId}?chapter=${chapterParam}`,
              "_blank",
            )
          }
        >
          ¥{formatCurrency(totalPrice)}
        </Button>
      </div>
    </>
  );
};
