import { NavLink } from "react-router-dom";
import styles from "../../../assets/styles/interviews.module.scss";
import { InterviewListType } from "../../../hooks/admin/useFetchInterviewList/type";

export const InterviewList = ({
  interviewList,
}: {
  interviewList: InterviewListType[];
}) => {
  return (
    <>
      {interviewList.map((interview, index) => (
        <div className={styles.interviews_item} key={index}>
          <div className={styles.thumbnail}>
            {interview.thumbnailLoadedError ? (
              <p style={{ padding: "14px" }}>
                ※「{interview.title}」 のサムネイルの読み込みができませんでした
              </p>
            ) : (
              <img
                src={interview.thumbnail}
                alt={`${interview.title}サムネイル`}
              />
            )}
          </div>
          <div className={styles.item_text}>
            <p className={styles.title}>{interview.title}</p>
            <div className={styles.btn}>
              <NavLink
                className={styles.btn_secondary}
                to={`/interview/${interview.id}`}
                target="_blank"
              >
                インタビューを読む
              </NavLink>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
