import HeadBlock from "../../../../components/HeadBlock";
import { useParams } from "react-router-dom";
import styles from "../../../../assets/styles/interview.module.scss";
import { useAuthContext } from "../../../../components/AuthContext";
import { useEffect, useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../firebase";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Content } from "./Content";
import { InterviewFormData } from "../../../../constants/features/interview/interview";
import { useFetchInterviewDetail } from "../../../../hooks/admin/useFetchInterviewDetail";

export const InterviewDetailPage = () => {
  const { user } = useAuthContext();
  const { interviewId } = useParams();
  const [interviewDetail, setInterviewDetail] = useState<InterviewFormData>();

  const { fetchInterviewDetail } = useFetchInterviewDetail(interviewId);

  const fetchData = async () => {
    const interviewData = await fetchInterviewDetail();
    setInterviewDetail(interviewData);
  };

  useEffect(() => {
    fetchData();
    logEvent(analytics, "view_interview_page", {
      is_login: !!user,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock
        title={interviewDetail?.title}
        path={`interview/${interviewId}`}
      />
      <div className={styles.Interview}>
        <Header isLoggedIn={!!user} interviewId={interviewId} />
        <Content
          isLoggedIn={!!user}
          interviewId={interviewId}
          interviewDetail={interviewDetail}
        />
        <Footer
          title={interviewDetail?.title}
          actors={interviewDetail?.actors}
        />
      </div>
    </>
  );
};
