import { createRef, useEffect, useState } from "react";
import HeadBlock from "../components/HeadBlock";
import styles from "../assets/styles/stream.module.scss";
import stylesIcon from "../assets/styles/icon.module.scss";
import stylesLoading from "../assets/styles/loading.module.scss";
import { Button } from "../components/Button";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "../components/AuthContext";
import logo from "../assets/images/content/logo.svg";
import { useGetOgpImageUrl } from "../hooks/reactQuery/useGetOgpImageUrl";
import { useFetchStreamData } from "../hooks/streams/useFetchStreamData";
import { RelatedVoiceDramaItem } from "./streams/RelatedVoiceDramaItem";

export const StreamsDetail = () => {
  const { user } = useAuthContext();
  const { streamsId } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const videoRef = createRef<HTMLAudioElement>();
  const videoContainerRef = createRef<HTMLDivElement>();
  const [shownStartModal, setShownStartModal] = useState<boolean>(true);
  const [shownRelationModal, setShownRelationModal] = useState<boolean>(false);
  const [isPausedVideo, setIsPausedVideo] = useState<boolean>(true);
  const [isEndedVideo, setIsEndedVideo] = useState<boolean>(false);

  const { data: streamOgpImageUrl } = useGetOgpImageUrl(streamsId || "");

  const { streamData, fetchStreamData } = useFetchStreamData();

  useEffect(() => {
    if (streamsId !== undefined) {
      fetchStreamData(streamsId);
    }
  }, [fetchStreamData, streamsId]);

  useEffect(() => {
    if (isEndedVideo) setShownRelationModal(true);
  }, [isEndedVideo]);

  const onPlaying = () => {
    setIsEndedVideo(false);
    setIsPausedVideo(false);
  };

  const onEnded = () => {
    setIsEndedVideo(true);
    setIsPausedVideo(false);
  };

  const onPause = () => {
    setIsPausedVideo(true);
  };

  const startVideo = () => {
    if (!videoRef.current) return;
    searchParams.set("isPlaying", "true");
    setSearchParams(searchParams, { replace: true });

    setShownStartModal(false);
    videoRef.current
      .play()
      .then(() => {
        setIsPausedVideo(false);
      })
      .catch(
        () =>
          videoRef.current?.play().then(() => {
            setIsPausedVideo(false);
          }),
      );
    videoRef.current.muted = false;
  };

  const clickButtonReplay = () => {
    if (!videoRef.current) return;
    videoRef.current.play();
    videoRef.current.currentTime = 0;
  };

  if (streamData.status === "loading") {
    return (
      <>
        <div className={stylesLoading.loading}>
          <p>Loading...</p>
        </div>
      </>
    );
  }

  if (streamData.status === "notExist") {
    // 配信IDが存在しない場合はボイスドラマ一覧にリダイレクト
    return <Navigate replace to="/contents" />;
  }

  return (
    <>
      <HeadBlock title={streamData.title} path={`streams/${streamsId}`} />
      <div className={styles.streams}>
        <div className={styles.contents_header}>
          <button
            type="button"
            className={styles.btn_close}
            onClick={() => {
              window.history.back();
            }}
          >
            <i className={stylesIcon.icon_close}></i>
          </button>
          <img src={logo} alt="" />
          {user ? (
            <div className={styles.login}>
              <i className={stylesIcon.icon_user_outline}></i>
              <p>ログイン中</p>
            </div>
          ) : (
            <Button
              size="s"
              variant="secondary"
              onClick={() => navigate("/signin")}
            >
              ログイン/登録
            </Button>
          )}
        </div>

        <div className={styles.video_wrap}>
          <div ref={videoContainerRef} className={styles.video}>
            <img src={streamOgpImageUrl} alt="" />
            <audio
              ref={videoRef}
              id={streamsId}
              src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/streams%2F${streamsId}.wav?alt=media`}
              onPlaying={onPlaying}
              onPause={onPause}
              onEnded={onEnded}
              muted
              controls
              controlsList="nofullscreen nodownload noremoteplayback noplaybackrate foobar"
              preload="none"
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>

          <div className={styles.contents_footer_btns}>
            <Button
              className={styles.btn_relation}
              size="s"
              variant={isPausedVideo ? "primary" : "secondary"}
              onClick={() => {
                setShownRelationModal(true);
                searchParams.set("relationPage", "true");
                setSearchParams(searchParams, { replace: true });
              }}
            >
              関連作品を見る
            </Button>
          </div>
          {isEndedVideo && (
            <div className={styles.announce_ended_video}>
              <Button variant="secondary" onClick={clickButtonReplay}>
                もう一度視聴する
              </Button>
            </div>
          )}
        </div>

        {shownStartModal && (
          <div className={styles.modal}>
            <div className={styles.inner}>
              <h2>
                以下の配信を
                <br />
                視聴しますか？
              </h2>
              <div className={styles.btn_close_wrap}>
                <button
                  type="button"
                  className={styles.btn_close}
                  onClick={() => window.history.back()}
                >
                  <i className={stylesIcon.icon_close}></i>
                </button>
              </div>
              <img src={streamOgpImageUrl} alt="" />
              <Button onClick={startVideo}>視聴する</Button>
            </div>
            <div className={styles.overlay}></div>
          </div>
        )}

        {shownRelationModal && (
          <div className={`${styles.modal} ${styles.modal_relation}`}>
            <div className={styles.inner}>
              <h2>関連作品</h2>
              <div className={styles.btn_close_wrap}>
                <button
                  type="button"
                  className={styles.btn_close}
                  onClick={() => {
                    setShownRelationModal(false);
                    searchParams.delete("relationPage");
                    setSearchParams(searchParams, { replace: true });
                  }}
                >
                  <i className={stylesIcon.icon_close}></i>
                </button>
              </div>
              {streamData.relatedVoiceDrama.length > 0 && (
                <ul className={styles.modal_relation_contents}>
                  {streamData.relatedVoiceDrama.map((voiceDrama) => (
                    <li key={voiceDrama.voiceDramaId}>
                      <RelatedVoiceDramaItem
                        voiceDramaId={voiceDrama.voiceDramaId}
                        title={voiceDrama.title}
                        totalChapterCount={voiceDrama.totalChapterCount}
                        totalChapterTime={voiceDrama.totalChapterTime}
                        chapterParam={
                          voiceDrama.totalChapterCount > 1
                            ? "all"
                            : // ひみつのはぐたいむでは現在1チャプターのため1をセット
                              `${voiceDrama.totalChapterCount}`
                        }
                        totalPrice={voiceDrama.totalPrice}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div
              className={styles.overlay}
              role="button"
              tabIndex={0}
              onClick={() => setShownRelationModal(false)}
              onKeyUp={() => setShownRelationModal(false)}
            ></div>
          </div>
        )}
      </div>
    </>
  );
};

export default StreamsDetail;
