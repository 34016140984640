import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";
import { useQuery } from "@tanstack/react-query";
import { Content } from "../../utils/types";

export const useGetOgpImageUrl = (contentId: Content["id"]) => {
  return useQuery({
    queryKey: ["ogpImage", contentId],
    enabled: !!contentId,
    queryFn: async () => {
      const ogpImageRef = ref(storage, `contents/ogp/${contentId}.png`);
      return getDownloadURL(ogpImageRef);
    },
  });
};
