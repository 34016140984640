import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
} from "firebase/firestore";
import { Stream } from "./type";

export const streamConverter: FirestoreDataConverter<Stream> = {
  toFirestore: (content: Stream) => {
    return {
      title: content.title,
      description: content.description,
      relatedVoiceDramaIds: content.relatedVoiceDramaIds,
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Stream => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      title: data.title,
      description: data.description,
      relatedVoiceDramaIds: data.relatedVoiceDramaIds,
    };
  },
};
