import { useNavigate } from "react-router-dom";
import HeadBlock from "../../../components/HeadBlock";
import styles from "../../../assets/styles/apply.module.scss";
import stylesIcon from "../../../assets/styles/icon.module.scss";
import { analytics, auth } from "../../../firebase";
import { logEvent } from "firebase/analytics";
import { useGetOgpImageUrl } from "../../../hooks/reactQuery/useGetOgpImageUrl";

function ApplyFuyuzoraMelt() {
  const navigate = useNavigate();
  const { data: ogpImageUrl } = useGetOgpImageUrl("fuyuzora-melt-reply");

  const click_purchase_button = () => {
    const user = auth.currentUser;
    logEvent(analytics, "click_purchase_button", {
      is_login: user ? true : false,
      timing: "present",
    });
    localStorage.setItem("purchase_button_click_location", "present");

    navigate("/purchase/fuyuzora-melt-reply?chapter=all");
  };

  return (
    <>
      <HeadBlock
        title={"「小林裕介さん サイン色紙」プレゼント詳細"}
        path="contents/fuyuzora-melt-reply/detail/present"
      />
      <div className={`${styles.Apply} ${styles.reply}`}>
        <div className={styles.modal}>
          <button
            className={styles.btnClose}
            type="button"
            onClick={() => navigate("/contents/fuyuzora-melt-reply/detail")}
          >
            <i className={stylesIcon.icon_close}></i>
          </button>
          <div className={styles.inner}>
            <section>
              <h4>参加方法</h4>
              <ol>
                <li>「想い、冬空メルト。〜reply〜」を全話ご購入</li>
                <li>
                  キャンペーン期間内に公式X（旧：Twitter）アカウントから投稿される応募用のポストに「ボイスドラマの感想」を記載し、引用リポスト
                </li>
              </ol>
              <p>↓↓↓下記からも作品を購入できます↓↓↓↓</p>
              <button
                type="button"
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
                onClick={click_purchase_button}
              >
                作品を購入する
              </button>
              <div
                className={styles.purchase_img}
                onClick={click_purchase_button}
              >
                <img src={ogpImageUrl} alt="想い、冬空メルト。〜reply〜" />
              </div>
            </section>
            <section>
              <h4>景品</h4>
              <p>「小林裕介さん 直筆サイン色紙」を2名様にプレゼント</p>
              <p className={styles.attention}>
                ※当選の色紙は1人1枚となります。(2枚セットではございません。)
              </p>
            </section>
            <section>
              <h4>キャンペーン期間</h4>
              <p>2月16日（金）18:00～2月23日（金）23:59</p>
            </section>
            <section>
              <h4>応募資格・応募条件</h4>
              <ol>
                <li>日本国内にお住まいの方</li>
                <li>
                  <p>
                    クリエイターコラボレーションサービス「PAZLE」にて「想い、冬空メルト。〜reply〜」を全話ご購入いただいた方
                  </p>
                  <p className={styles.attention}>
                    ※弊社にて購入履歴が確認できない場合は、参加とみなされません。
                  </p>
                </li>
                <li>
                  <p>
                    キャンペーン期間内に公式Xアカウントから投稿される応募用のポスト応募用のポストに「ボイスドラマの感想」を記載し、引用リポストいただいた方
                  </p>
                  <p className={styles.attention}>
                    ※必ずご自身のアカウントを“公開”にした状態で参加ください。アカウントが非公開の場合は参加とみなされません。
                  </p>
                  <p className={styles.attention}>
                    ※ダイレクトメッセージを受信拒否設定している場合、参加とみなされません。
                  </p>
                </li>
              </ol>
            </section>
            <section>
              <h4>抽選・当選発表</h4>
              <ul>
                <li>
                  厳正なる抽選の上、ご当選者様にはクリエイターコラボレーションサービス「PAZLE」公式アカウントよりXのダイレクトメッセージにてキャンペーン期間後に当選連絡をいたします。
                </li>
                <li>
                  <p>
                    当選発表は、ダイレクトメッセージの当選連絡をもって代えさせていただきます。
                  </p>
                  <p className={styles.attention}>
                    ※弊社にて購入履歴が確認できない場合は、当選権利が他の方に移行します。
                  </p>
                </li>
                <li>賞品発送は2024年3月中を予定しております。</li>
              </ul>
              <p className={styles.attention}>
                ※やむを得ない事情により賞品の発送が若干遅れる場合がありますので予めご了承ください。
              </p>
              <p className={styles.attention}>
                ※賞品のお届け先は、日本国内のみとさせていただきます。
              </p>
              <p className={styles.attention}>
                ※賞品のお届け先は、応募されるご本人様の住所に限らせていただきます。
              </p>
              <p className={styles.attention}>
                ※賞品のお届け先が不明などでお届けできない場合は、当選を無効とさせていただきます。
              </p>
              <p className={styles.attention}>
                ※応募の受付、当選確認に関するお問合せ、及び応募後の住所等変更はお受けできません。
              </p>
              <p className={styles.attention}>
                ※当選の権利は第三者への譲渡や現金とのお引き換えはできません。
              </p>
              <p className={styles.attention}>
                ※当選の賞品を第三者へ譲渡、または換金することはできません。
              </p>
              <p className={styles.attention}>
                ※本キャンペーンの賞品をオークションに出品する等の転売行為は禁止致します。
              </p>
            </section>
            <section>
              <h4>個人情報について</h4>
              <ul>
                <li>
                  当選後にご記入いただく個人情報は、当選者への賞品の発送、本件に関する諸連絡のみに利用させていただきます。
                </li>
                <li>
                  お客様の個人情報はVECTOR合同会社にて管理させていただきます。
                </li>
              </ul>
            </section>
          </div>
          <div
            className={styles.overlay}
            onClick={() => navigate("/contents/fuyuzora-melt-reply/detail")}
          ></div>
        </div>
      </div>
    </>
  );
}

export default ApplyFuyuzoraMelt;
