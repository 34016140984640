import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
} from "firebase/firestore";
import type { VoiceDrama } from "./type";

export const voiceDramaConverter: FirestoreDataConverter<VoiceDrama> = {
  toFirestore: (content: VoiceDrama) => {
    return {
      title: content.title,
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): VoiceDrama => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      title: data.title,
    };
  },
};
