import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { contentsCollectionConverter } from "../../voiceDrama/converter";

export const useFetchVoiceDramaDetail = (contentId: string | undefined) => {
  const fetchVoiceDramaDetail = async () => {
    if (!contentId) return;
    try {
      const docRef = doc(db, "contents", contentId).withConverter(
        contentsCollectionConverter,
      );
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    } catch (error) {
      console.error(error);
    }
  };

  return { fetchVoiceDramaDetail };
};
