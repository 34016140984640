import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
} from "firebase/firestore";
import type { Actor } from "./type";

export const actorConverter: FirestoreDataConverter<Actor> = {
  toFirestore: (content: Actor) => {
    return {
      id: content.id,
      description: content.description,
      firstName: content.firstName,
      lastName: content.lastName,
      name: content.name,
      yomiKana: content.yomiKana,
    };
  },
  fromFirestore: (
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): Actor => {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      description: data.description,
      firstName: data.firstName,
      lastName: data.lastName,
      name: data.name,
      yomiKana: data.yomiKana,
    };
  },
};
