export const VALIDATION_ERROR_MESSAGE = {
  REQUIRED: "必須です",
  NO_EMPTY: "1つ以上選択してください",
  INT: "整数を入力してください",
  NON_NEGATIVE: "0以上を入力してください",
  MAX_STRING_LENGTH: (max: number) => `${max}字以下で入力してください`,
  MIN_STRING_LENGTH: (min: number) => `${min}字以上で入力してください`,
  MAX_VALUE: (max: number) => `${max}以下を入力してください`,
  MIN_VALUE: (min: number) => `${min}以上を入力してください`,
  //   EMAIL: "不正なメールアドレスの形式です",
  //   PASSWORD:
  //     "大文字、小文字、数字、特殊記号（^ $ * . [ ] { } ( ) ? - ” ! @ # % & / \\ , > < ' : ; | _ ~ ` + =）を含む8文字以上かつ256文字以下のパスワードを入力してください",
  //   NEW_PASSWORD_CHECK:
  //     "新しいパスワードと新しいパスワード（確認）の値が一致しません",
  URL: "不正なURLの形式です",
  DATE_TIME: "不正な時刻の形式です",
  FILE_SIZE: (max: number) => `ファイルサイズが${max}MBを超えています`,
  FILE_TYPE: "ファイル形式が正しくありません",
} as const;

export const API_ERROR_MESSAGE = {
  NOT_FOUND: (target: string) =>
    `お探しの${target}は既に削除済みか存在しません`,
  UNKNOWN: "エラーが発生しました。時間を置いてから再度お試しください",
};
