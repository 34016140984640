import { useState } from "react";
import { InterviewListType } from "./type";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase";
import { interviewListConverter } from "./interviewConverter";

export const useFetchInterviewList = () => {
  const [interviewList, setInterviewList] = useState<InterviewListType[]>([]);
  const [loadingStatus, setLoadingStatus] = useState<
    "initialized" | "succeeded" | "failed"
  >("initialized");

  const [isImagesLoaded, setIsImagesLoaded] = useState(false);

  const fetchInterviewList = async () => {
    try {
      const contentsRef = collection(db, "interviews").withConverter(
        interviewListConverter,
      );

      const q = query(contentsRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const interviews = querySnapshot.docs.map((doc) => doc.data());

      const imageLoadPromises = interviews
        .filter((interview) => interview.thumbnail)
        .map((interview) => {
          return new Promise<{ id: string; error: boolean }>((resolve) => {
            const img = new Image();
            img.onload = () => resolve({ id: interview.id, error: false });
            img.onerror = () => {
              resolve({ id: interview.id, error: true });
            };
            img.src = interview.thumbnail;
          });
        });

      const results = await Promise.all(imageLoadPromises);

      setInterviewList(() =>
        interviews.map((interview) => {
          const result = results.find((r) => r.id === interview.id);
          return {
            ...interview,
            thumbnailLoadedError: result?.error ?? false,
          };
        }),
      );

      setLoadingStatus("succeeded");
      setIsImagesLoaded(true); // 画像の読み込みが完了してから設定
    } catch (error) {
      setLoadingStatus("failed");
      console.error("Error fetching interview list: ", error);
    }
  };

  return {
    loadingStatus,
    isImagesLoaded,
    interviewList,
    fetchInterviewList,
  };
};
