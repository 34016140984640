import { useGetOgpImageUrl } from "../../hooks/reactQuery/useGetOgpImageUrl";
import styles from "../../assets/styles/contentsDetail.module.scss";

export const RelatedContentCard = ({
  targetContentId,
  referrer,
}: {
  targetContentId: string;
  referrer: string;
}) => {
  const { data: ogpImage } = useGetOgpImageUrl(targetContentId);
  return (
    <a
      href={`/contents/${targetContentId}?relation=${referrer}`}
      target="_blank"
      className={styles.relatedWorksImg}
      rel="noreferrer"
    >
      <img src={ogpImage} alt="" />
    </a>
  );
};
