import { NavLink } from "react-router-dom";
import styles from "../../assets/styles/contentsDetail.module.scss";
import { useGetOgpImageUrl } from "../../hooks/reactQuery/useGetOgpImageUrl";

interface StreamForContentsDetailProps {
  streamId: string;
}

export const StreamItem = ({ streamId }: StreamForContentsDetailProps) => {
  const { data: streamImageUrl } = useGetOgpImageUrl(streamId);

  return (
    <NavLink to={`/streams/${streamId}`} className={styles.relatedWorksImg}>
      <img src={streamImageUrl} alt="" />
    </NavLink>
  );
};
