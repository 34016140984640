export type ContentCategoryType =
  | "YOUTH"
  | "GL"
  | "BL"
  | "SF"
  | "FANTAGY"
  | "PR"
  | "LIFE";
export const displayContentCategory = (
  category: ContentCategoryType,
): string => {
  switch (category) {
    case "YOUTH":
      return "琴線を揺さぶる青春の物語";
    case "SF":
      return "SF";
    case "GL":
      return "百合";
    case "BL":
      return "BL";
    case "FANTAGY":
      return "ファンタジー";
    case "PR":
      return "プロモーション";
    case "LIFE":
      return "日常";
    default:
      return "";
  }
};
