import HeadBlock from "../../components/HeadBlock";
import Nav from "../../components/Nav";
import { StaticInterviewList } from "./StaticInterviewList";
import { InterviewList } from "./InterviewList";
import { useEffect, useRef } from "react";
import styles from "../../assets/styles/interviews.module.scss";
import loadingStyles from "../../assets/styles/loading.module.scss";
import { useFetchInterviewList } from "../../hooks/admin/useFetchInterviewList";

function Interviews() {
  const interviewsRef = useRef<HTMLDivElement>(null);
  const { loadingStatus, isImagesLoaded, interviewList, fetchInterviewList } =
    useFetchInterviewList();

  useEffect(() => {
    const interviews = interviewsRef.current;
    if (!interviews) return;

    // 初期状態ではスナップを無効化
    interviews.style.scrollSnapType = "none";

    let isFirstScroll = true;

    const handleScroll = () => {
      if (isFirstScroll) {
        isFirstScroll = false;
        return;
      }
      // 最初のスクロール以降はスナップを有効化
      interviews.style.scrollSnapType = "y mandatory";
    };

    interviews.addEventListener("scroll", handleScroll);

    fetchInterviewList();

    return () => {
      interviews.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadBlock title="Creator’s interview" path="interviews" />
      <div className={styles.Interviews}>
        <div className={styles.interviews} ref={interviewsRef}>
          {loadingStatus === "initialized" && !isImagesLoaded && (
            <div className={loadingStyles.loading}>
              <p>Loading...</p>
            </div>
          )}
          {loadingStatus === "succeeded" && isImagesLoaded && (
            <>
              {/* 管理画面から新規で作成したデータを表示させるコンポーネント */}
              <InterviewList interviewList={interviewList} />
              {/* 既存の静的に保持しているデータを表示するコンポーネント */}
              <StaticInterviewList />
            </>
          )}
        </div>
      </div>
      <Nav />
    </>
  );
}

export default Interviews;
