import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useCallback, useState } from "react";
import { db } from "../../../firebase";
import { streamConverter } from "./streamConverter";
import type { Stream, StreamFetchStatus } from "./type";
import { voiceDramaConverter } from "./voiceDramaConverter";
import { voiceDramaChapterConverter } from "./voiceDramaChapterConverter";

interface RelatedVoiceDrama {
  voiceDramaId: string;
  title: string;
  totalChapterCount: number;
  totalChapterTime: number;
  totalPrice: number;
}

type StreamDataState = { status: StreamFetchStatus } & Omit<
  Stream,
  "relatedVoiceDramaIds"
> & {
    relatedVoiceDrama: RelatedVoiceDrama[];
  };

export const useFetchStreamData = () => {
  const [streamData, setStreamData] = useState<StreamDataState>({
    status: "loading",
    id: "",
    title: "",
    description: "",
    relatedVoiceDrama: [],
  });

  // 配信に紐づく関連作品情報をチャプター情報を整理して返却
  const fetchRelatedVoiceDramaData = async (
    voiceDramaList: { id: string; title: string }[],
  ) => {
    return await Promise.all(
      voiceDramaList.map(async (voiceDrama) => {
        const querySnapshot = await getDocs(
          collection(db, "contents", voiceDrama.id, "chapters").withConverter(
            voiceDramaChapterConverter,
          ),
        );

        // 当該ボイスドラマの総チャプター数、総再生時間、総価格を計算
        return querySnapshot.docs.reduce<RelatedVoiceDrama>(
          (acc, doc) => {
            const data = doc.data();
            return {
              voiceDramaId: voiceDrama.id,
              title: voiceDrama.title,
              totalChapterCount: acc.totalChapterCount + 1,
              totalChapterTime: acc.totalChapterTime + data.totalTime,
              totalPrice: acc.totalPrice + data.price,
            };
          },
          {
            voiceDramaId: "",
            title: "",
            totalChapterCount: 0,
            totalChapterTime: 0,
            totalPrice: 0,
          },
        );
      }),
    );
  };

  const fetchStreamData = useCallback(
    async (streamId: string) => {
      try {
        const streamRef = doc(
          collection(db, "streams"),
          streamId,
        ).withConverter(streamConverter);
        const streamSnapshot = await getDoc(streamRef);

        if (streamSnapshot.exists()) {
          const { relatedVoiceDramaIds, ...streamData } = streamSnapshot.data();

          const contentsRef = collection(db, "contents").withConverter(
            voiceDramaConverter,
          );

          if (relatedVoiceDramaIds.length === 0) {
            // 関連作品IDがない場合は、以降のクエリでエラーが発生するため空配列で返却
            setStreamData({
              ...streamData,
              status: "exist",
              relatedVoiceDrama: [],
            });
            return;
          }

          // 配信に紐づく関連作品情報をクエリ取得
          const q = query(
            contentsRef,
            where(documentId(), "in", relatedVoiceDramaIds),
          );
          const querySnapshot = await getDocs(q);
          const voiceDramaMapping = querySnapshot.docs.reduce<
            Record<string, { id: string; title: string }>
          >((acc, doc) => {
            const data = doc.data();
            acc[data.id] = {
              id: data.id,
              title: data.title,
            };
            return acc;
          }, {});

          // firestoreで定義した関連作品の並び順にチャプター情報を取得
          const voiceDramaList = relatedVoiceDramaIds.map(
            (id) => voiceDramaMapping[id],
          );

          const chapters = await fetchRelatedVoiceDramaData(voiceDramaList);

          setStreamData({
            ...streamData,
            status: "exist",
            relatedVoiceDrama: chapters,
          });
        } else {
          setStreamData({
            ...streamData,
            status: "notExist",
          });
        }
      } catch (error) {
        console.error("Error fetching stream detail: ", error);
        setStreamData({
          ...streamData,
          status: "notExist",
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { streamData, fetchStreamData };
};
