import styles from "../../../../../assets/styles/interview.module.scss";
import { InterviewFormData } from "../../../../../constants/features/interview/interview";
import { Conversations } from "./Conversations";
import { ActorIntroduction } from "./ActorIntroduction";
import { collection, getCountFromServer } from "firebase/firestore";
import { db } from "../../../../../firebase";
import { useEffect, useState } from "react";

export const Content = ({
  isLoggedIn,
  interviewId,
  interviewDetail,
}: {
  isLoggedIn: boolean;
  interviewId: string | undefined;
  interviewDetail: InterviewFormData | undefined;
}) => {
  const [chapterCount, setChapterCount] = useState<number>(0);

  const fetchChaptersCount = async () => {
    try {
      const chaptersRef = collection(db, `contents/${interviewId}/chapters`);
      const snapshot = await getCountFromServer(chaptersRef);
      setChapterCount(snapshot.data().count);
    } catch (error) {
      console.error("チャプター数の取得に失敗しました:", error);
    }
  };

  useEffect(() => {
    fetchChaptersCount();
  }, []);

  return (
    <main className={styles.main} id="main">
      <div className={styles.body}>
        <h1> {interviewDetail?.title}</h1>

        <p>話題の声優さんのボイスドラマを配信する「PAZLE（Beta版）」。</p>
        <p>{interviewDetail?.introduction}</p>

        <Conversations
          isLoggedIn={isLoggedIn}
          interviewId={interviewId}
          conversations={interviewDetail?.conversations}
        />

        {isLoggedIn && <ActorIntroduction actors={interviewDetail?.actors} />}

        <div className={styles.btnWrap}>
          {chapterCount !== 1 && <p>＼{chapterCount}話中１話目無料／</p>}
          <a
            href={`/contents/${interviewId}`}
            target="_blank"
            rel="noreferrer"
            className={`${styles.btn} ${styles.btnMedium} ${
              isLoggedIn ? styles.btnPrimary : styles.btnSecondary
            }`}
          >
            <span>ボイスドラマを視聴する</span>
          </a>
          <a
            href={`/contents/${interviewId}`}
            target="_blank"
            className={`${styles.btn} ${styles.btnMedium} ${styles.btnTertiary}`}
            rel="noreferrer"
          >
            作品詳細はこちら
          </a>
        </div>
      </div>

      {interviewDetail?.cspImage &&
        typeof interviewDetail?.cspImage === "string" && (
          <div className={styles.csp}>
            <p>
              PAZLEはクリエイターを
              <br className={styles.brSp} />
              支援しています！
            </p>
            <img
              className={styles.imgPc}
              src={interviewDetail.cspImage}
              alt=""
            />
          </div>
        )}
      <img
        src={require("../../../../../assets/images/content/footer.jpg")}
        width={"100%"}
        alt=""
      />
    </main>
  );
};
