import styles from "../../../../assets/styles/interview.module.scss";
import stylesIcon from "../../../../assets/styles/icon.module.scss";

export const Header = ({
  isLoggedIn,
  interviewId,
}: {
  isLoggedIn: boolean;
  interviewId: string | undefined;
}) => {
  return (
    <header className={styles.header}>
      <div className={styles.headerWrap}>
        <img
          src={require("../../../../assets/images/interview/logo.svg").default}
          alt="PAZLE Creator’s interview"
        />
        <a href="#sns" className={styles.pageToSns}>
          <i className={stylesIcon.icon_arrow_down_circle}></i>
          SNSをフォローする
        </a>
        <div className={styles.headerBtns}>
          <a
            href="https://x.com/Pazle_info "
            target="_brank"
            className={`${styles.btnTw}`}
          >
            フォローする
          </a>
          <a
            href="https://lin.ee/r4SVWtA"
            target="_brank"
            className={styles.btnLine}
          >
            <img
              src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
              alt="友だち追加"
            />
          </a>
          <a
            href={`/contents/${interviewId}`}
            target="_blank"
            rel="noreferrer"
            className={`${styles.btn} ${styles.btnSmall} ${
              isLoggedIn ? styles.btnPrimary : styles.btnSecondary
            }`}
          >
            <span>
              ボイスドラマ
              <br className={styles.brSp} />
              を視聴する
            </span>
          </a>
        </div>
      </div>
    </header>
  );
};
