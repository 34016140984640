import React from "react";
import { signOut } from "firebase/auth";
import { useAuthContext } from "../../components/AuthContext";
import HeadBlock from "../../components/HeadBlock";
import Header from "../../components/Header";
import Nav from "../../components/Nav";
import { auth } from "../../firebase";
import styles from "../../assets/styles/menu.module.scss";
import { useNotificationContext } from "../../components/Notification";
import { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";

function Menu() {
  const { user, setUser } = useAuthContext();
  const { setText } = useNotificationContext();
  const location = useLocation();

  const logout = () => {
    const result = window.confirm("ログアウトしますか？");
    if (result) {
      signOut(auth)
        .then(() => {
          setText("ログアウトしました");
          setUser(auth.currentUser);
        })
        .catch((e) => {
          alert(e);
        });
    }
  };

  useEffect(() => {
    if (location.state && location.state.notificationText)
      setText(location.state.notificationText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user) {
    return (
      <>
        <HeadBlock title={"メニュー"} path="menu" />
        <Header />
        <div className={styles.Menu}>
          <div className={styles.inner}>
            <h1>メニュー</h1>
            <ul className={styles.snsList}>
              <li>
                <NavLink
                  className={styles.line}
                  to="https://lin.ee/r4SVWtA"
                  target="_brank"
                >
                  <img
                    src={require("../../assets/images/line_btn.svg").default}
                    alt=""
                  />
                  <p>友だち追加</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={styles.tw}
                  to="https://twitter.com/Pazle_info"
                  target="_brank"
                >
                  <img
                    src={require("../../assets/images/x_btn.svg").default}
                    alt=""
                  />
                  <p>フォローする</p>
                </NavLink>
              </li>
            </ul>
            <NavLink className={styles.banner} to="/about" target="_brank">
              <img src={require("../../assets/images/banner.png")} alt="" />
            </NavLink>
            <ul>
              <li>
                <NavLink to="/menu/profile/edit">プロフィール</NavLink>
              </li>
            </ul>
            <ul>
              <li>
                <NavLink
                  to="https://docs.google.com/forms/d/e/1FAIpQLSdnS9xymPyO5EXHsHTIDrDwbTn3qMaRTw1W2vHft_nDE14KBA/viewform"
                  target="_blank"
                >
                  お問い合わせ
                </NavLink>
              </li>
              <li>
                <NavLink to="https://vectordesign.jp/" target="_brank">
                  運営会社について
                </NavLink>
              </li>
              <li>
                <NavLink to="/terms" target="_brank">
                  利用規約
                </NavLink>
              </li>
              <li>
                <NavLink to="/privacy" target="_brank">
                  プライバシーポリシー
                </NavLink>
              </li>
              <li>
                <NavLink to="/tokutei" target="_brank">
                  特定商取引法に基づく表記
                </NavLink>
              </li>
              <li>
                <NavLink to="/requirements" target="_brank">
                  動作環境について
                </NavLink>
              </li>
            </ul>
            <button type="button" className={styles.logout} onClick={logout}>
              ログアウト
            </button>
          </div>
        </div>
        <Nav />
      </>
    );
  } else {
    return (
      <>
        <HeadBlock title={"メニュー"} path="menu" />
        <Header />
        <div className={styles.Menu}>
          <div className={styles.inner}>
            <h1>メニュー</h1>
            <NavLink className={styles.btn_primary} to="/signin">
              ログイン / 登録
            </NavLink>
            <ul className={styles.snsList}>
              <li>
                <NavLink
                  className={styles.line}
                  to="https://lin.ee/r4SVWtA"
                  target="_brank"
                >
                  <img
                    src={require("../../assets/images/line_btn.svg").default}
                    alt=""
                  />
                  <p>友だち追加</p>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={styles.tw}
                  to="https://twitter.com/Pazle_info"
                  target="_brank"
                >
                  <img
                    src={require("../../assets/images/x_btn.svg").default}
                    alt=""
                  />
                  <p>フォローする</p>
                </NavLink>
              </li>
            </ul>
            <NavLink className={styles.banner} to="/about" target="_brank">
              <img src={require("../../assets/images/banner.png")} alt="" />
            </NavLink>
            <ul>
              <li>
                <NavLink
                  to="https://docs.google.com/forms/d/e/1FAIpQLSdnS9xymPyO5EXHsHTIDrDwbTn3qMaRTw1W2vHft_nDE14KBA/viewform"
                  target="_brank"
                >
                  お問い合わせ
                </NavLink>
              </li>
              <li>
                <NavLink to="https://vectordesign.jp/" target="_brank">
                  運営会社について
                </NavLink>
              </li>
              <li>
                <NavLink to="/terms" target="_brank">
                  利用規約
                </NavLink>
              </li>
              <li>
                <NavLink to="/privacy" target="_brank">
                  プライバシーポリシー
                </NavLink>
              </li>
              <li>
                <NavLink to="/tokutei" target="_brank">
                  特定商取引法に基づく表記
                </NavLink>
              </li>
              <li>
                <NavLink to="/requirements" target="_brank">
                  動作環境について
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <Nav />
      </>
    );
  }
}

export default Menu;
