import { z as zod } from "zod";
import { z } from "../../../libs/zod";
import { fileNameValidator } from "./util";

//  "first-second.png" → true
//  "ikumi-hase-gawa.txt" → false
const actorImageRegex = /^[a-z]+[-]?[a-z]+$/;

export const ActorsFormSchema = z.array(
  z.object({
    name: z.requiredString(),
    role: z.requiredString(),
    image: z
      .union([z.string(), z.custom<File>()])
      .refine(
        (value) => {
          if (typeof value === "string" && value.trim().length === 0) {
            return false;
          }
          return true;
        },
        { message: "ファイルを選択してください" },
      )
      .refine(
        (value) => {
          if (value instanceof File) {
            fileNameValidator(value, actorImageRegex);
            return true;
          }
          return typeof value === "string";
        },
        {
          message:
            "英小文字とハイフンを含めて入力してください（例: taro-yamada）",
        },
      ),
  }),
);

export type ActorFormData = zod.infer<typeof ActorsFormSchema>;
