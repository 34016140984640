import { z } from "../../../libs/zod";
import { ActorsFormSchema } from "./actors";

const chapterBase = z.object({
  price: z.requiredNumber(),
  sampleTotalTime: z.requiredNumber(),
  totalTime: z.requiredNumber(),
  outline: z.string().optional(),
  video: z.union([z.string(), z.custom<File>()]).refine(
    (value) => {
      if (typeof value === "string" && value.trim().length === 0) {
        return false;
      }
      return true;
    },
    { message: "ファイルを選択してください" },
  ),
  publishedStatus: z.enum(["PUBLISHED", "DRAFT"]),
});

export const chapterFormSchema = z.object({
  ...chapterBase.shape,
  actors: ActorsFormSchema,
});

export const chaptersCollectionFormSchema = z.array(chapterFormSchema);
