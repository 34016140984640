import { FirestoreDataConverter } from "firebase/firestore";
import { Interview } from "./type";

export const interviewConverter: FirestoreDataConverter<Interview> = {
  toFirestore(data: Interview): Interview {
    return {
      title: data.title,
      introduction: data.introduction,
      conversations: data.conversations,
      thumbnail: data.thumbnail,
      actors: data.actors,
      relatedVoiceDrama: data.relatedVoiceDrama,
      cspImage: data.cspImage,
    };
  },
  fromFirestore(snapshot, options): Interview {
    const data = snapshot.data(options);
    return {
      title: data.title,
      introduction: data.introduction,
      conversations: data.conversations,
      actors: data.actors,
      thumbnail: data.thumbnail,
      relatedVoiceDrama: data.relatedVoiceDrama,
      cspImage: data.cspImage,
      createdAt: data.createdAt,
      updatedAt: data?.updatedAt,
    };
  },
};
