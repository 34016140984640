import { collection, getDocs } from "firebase/firestore";
import { Actor } from "./type";
import { db } from "../../../firebase";
import { actorConverter } from "./actorConverter";
import { useQuery } from "@tanstack/react-query";

const fetchActors = async (): Promise<Actor[]> => {
  try {
    const querySnapshot = await getDocs(
      collection(db, "actors").withConverter(actorConverter),
    );
    return querySnapshot.docs.map((doc) => doc.data());
  } catch (error) {
    console.error("Failed to fetch actors:", error);
    throw new Error("Failed to fetch actors");
  }
};

export const useFetchActorList = () => {
  return useQuery({
    queryKey: ["actors"],
    queryFn: fetchActors,
  });
};
