import { forwardRef, MutableRefObject, useEffect, useState } from "react";
import styles from "../assets/styles/contents.module.scss";
import stylesIcon from "../assets/styles/icon.module.scss";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Content } from "../utils/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { auth, db, functions } from "../firebase";
import {
  collection,
  doc,
  documentId,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useSwipeable } from "react-swipeable";
import { ContentsView } from "../components/ContentsView";
import { PaymentMethodModal } from "../components/PaymentMethodModal";
import { SigninModal } from "../components/SigninModal";
import { useGetOgpImageUrl } from "../hooks/reactQuery/useGetOgpImageUrl";

interface Props {
  c: Content;
  purchasedContent: { id: string; chapters: number[] };
  index: number;
  currentContentId: string | undefined;
}

export const ContentsList = forwardRef<HTMLDivElement, Props>(
  ({ c, purchasedContent, index, currentContentId }, ref) => {
    const containerRef = ref as MutableRefObject<HTMLDivElement>;
    const [showContentsModal, setShowContentsModal] = useState<string | null>(
      null,
    );
    const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(false);
    const [showPaymentMethodModal, setShowPaymentMethodModal] = useState<
      string | null
    >(null);
    const [shownModalSignin, setShownModalSignin] = useState<string | null>(
      null,
    );
    const [chapter, setChapter] =
      useState<{ chapter: number; price: number }[]>();
    const analytics = getAnalytics();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const date: Date = new Date();
    date.setDate(date.getDate() - 14);
    const isNew = c.createdAt ? c.createdAt > date : false;
    const isPurchasedAll =
      c.chapters.length === 1
        ? purchasedContent &&
          c.chapters.length === purchasedContent.chapters.length
        : purchasedContent && purchasedContent.chapters.length === 2;
    const { data: ogpImageUrl } = useGetOgpImageUrl(c.id);

    useEffect(() => {
      if (!searchParams.get("isPlaying")) {
        setShowContentsModal(null);
      } else {
        if (currentContentId === c.id) {
          const chapter = Number(searchParams.get("chapter"));
          if (!c.chapters[chapter - 1].videoUrl) {
            setIsLoadingVideo(true);
            getVideoUrl(c.id, chapter - 1).then((url) => {
              c.chapters[chapter - 1].videoUrl = url;
              setShowContentsModal(c.id);
              setIsLoadingVideo(false);
              setShowContentsModal(currentContentId ? currentContentId : null);
            });
          } else {
            setIsLoadingVideo(false);
            setShowContentsModal(currentContentId ? currentContentId : null);
          }
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    const getVideoUrl = async (id: string, cIndex: number): Promise<string> => {
      if (c.chapters[cIndex].price === 0) {
        const getContentUrl = httpsCallable(functions, "getContentUrl");
        const url = getContentUrl({
          contentId: `${id}_${c.chapters[cIndex].index}`,
        }).then((result) => result.data as string);
        return url;
      } else {
        if (
          purchasedContent &&
          purchasedContent.chapters.includes(c.chapters[cIndex].index)
        ) {
          const getContentUrl = httpsCallable(functions, "getContentUrl");
          if (c.chapters.length > 1) {
            const url = getContentUrl({
              contentId: `${id}_${c?.chapters[cIndex].index}`,
            }).then((result) => result.data as string);
            return url;
          } else {
            const url = getContentUrl({ contentId: id }).then(
              (result) => result.data as string,
            );
            return url;
          }
        } else {
          return `https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/o/contents%2Fsamples%2F${id}.mp4?alt=media`;
        }
      }
    };

    const handlers = useSwipeable({
      onSwiped: (event) => {
        if (event.dir === "Up" || event.dir === "Down") {
          navigate(`/contents?id=${c.id}`, { replace: true });
        }
      },
      trackMouse: true,
    });

    const convertTime = (second: number): string => {
      const timeM = Math.floor(((second % (24 * 60 * 60)) % (60 * 60)) / 60);
      const timeS = ((second % (24 * 60 * 60)) % (60 * 60)) % 60;
      if (timeM > 0) return `${timeM}分${timeS}秒`;
      else return `${timeS}秒`;
    };

    const clickButtonBuy = (
      content: Content,
      chapter: { chapter: number; price: number }[],
      isInBulk: boolean,
    ) => {
      setChapter(chapter);
      const user = auth.currentUser;

      logEvent(analytics, "click_purchase_button", {
        is_login: user ? true : false,
        timing: "before_watching",
        is_in_bulk: isInBulk,
      });
      localStorage.setItem("purchase_button_click_location", "before_watching");

      if (!user) {
        setShownModalSignin(c.id);
        return;
      }

      (async () => {
        let isPurchased: boolean;
        if (chapter.length === 1) {
          const docSnap = await getDoc(
            doc(
              db,
              "users",
              user.uid,
              "contents",
              content.id,
              "chapters",
              String(chapter[0].chapter),
            ),
          );
          isPurchased = docSnap.exists();
        } else {
          const docSnap = await getDocs(
            query(
              collection(
                db,
                "users",
                user.uid,
                "contents",
                content.id,
                "chapters",
              ),
              where(documentId(), "in", ["2", "3"]),
            ),
          );
          isPurchased = docSnap.docs.length > 0;
        }

        if (isPurchased) {
          alert("すでに購入された作品です");
          logEvent(analytics, "completed_purchase", {
            timing: localStorage.getItem("purchase_button_click_location"),
            unpurchased: false,
          });
          navigate(
            `/my-contents?id=${content.id}&chapter=${chapter[0].chapter}&isPlaying=true`,
          );
          return;
        } else {
          setShowPaymentMethodModal(c.id);
        }
      })();
    };

    return (
      <>
        <div className={styles.contents_item} ref={containerRef}>
          <div className={styles.contents_item_wrap}>
            <div
              className={styles.thumbnail}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (window as any).twq("event", "tw-ofkye-ofypp", {});
                logEvent(analytics, "click_play_button");
                navigate(`/contents?id=${c.id}&chapter=1&isPlaying=true`);
              }}
            >
              {isNew && <p className={styles.new}>New</p>}
              <img src={ogpImageUrl} alt="" />
            </div>
            <div className={styles.item_text}>
              <p className={styles.title}>{c.title}</p>
              <p className={styles.time}>
                全{c.chapters.length}チャプター /{" "}
                {convertTime(
                  c.chapters.reduce((sum, ch) => sum + ch.totalTime, 0),
                )}
                （無料視聴：
                {c.chapters.length === 1
                  ? `${c.chapters[0].sampleTotalTime}秒`
                  : "Chapter.1"}
                ）
              </p>
              <div className={styles.btns}>
                <button
                  type="button"
                  className={styles.btn_secondary}
                  onClick={() => navigate(`/contents/${c.id}`)}
                >
                  詳細を見る
                </button>
                {c.chapters.length === 1 ? (
                  <button
                    type="button"
                    className={styles.btn_primary}
                    onClick={() =>
                      clickButtonBuy(
                        c,
                        [
                          {
                            chapter: c.chapters[0].index,
                            price: c.chapters[0].price,
                          },
                        ],
                        false,
                      )
                    }
                    disabled={isPurchasedAll ? true : false}
                  >
                    {isPurchasedAll && (
                      <i className={stylesIcon.icon_check}></i>
                    )}
                    {isPurchasedAll
                      ? "購入済み"
                      : `¥${c.chapters.reduce(
                          (sum, ch) => sum + Number(ch.price),
                          0,
                        )} で購入`}
                  </button>
                ) : (
                  <button
                    type="button"
                    className={styles.btn_primary}
                    onClick={() =>
                      clickButtonBuy(
                        c,
                        !purchasedContent ||
                          purchasedContent.chapters.length === 0
                          ? [
                              {
                                chapter: c.chapters[1].index,
                                price: c.chapters[1].price,
                              },
                              {
                                chapter: c.chapters[2].index,
                                price: c.chapters[2].price,
                              },
                            ]
                          : purchasedContent.chapters.includes(3)
                            ? [
                                {
                                  chapter: c.chapters[1].index,
                                  price: c.chapters[1].price,
                                },
                              ]
                            : [
                                {
                                  chapter: c.chapters[2].index,
                                  price: c.chapters[2].price,
                                },
                              ],
                        true,
                      )
                    }
                    disabled={isPurchasedAll ? true : false}
                  >
                    {isPurchasedAll && (
                      <i className={stylesIcon.icon_check}></i>
                    )}
                    {isPurchasedAll
                      ? "購入済み"
                      : !purchasedContent ||
                          purchasedContent.chapters.length === 0
                        ? "まとめて購入"
                        : "続きを購入"}
                  </button>
                )}
              </div>
              {c.highlight && (
                <pre className={styles.highlight}>
                  {c.highlight.replaceAll("\\n", "\n")}
                </pre>
              )}
            </div>
          </div>
          <input type="hidden" name="contentTitle" value={c.title} />
          <input type="hidden" name="contentId" value={c.id} />
          <input type="hidden" name="index" value={index} />

          {isLoadingVideo && (
            <div className={styles.loading_video}>
              <p>読み込み中...</p>
            </div>
          )}

          {showContentsModal === c.id && (
            <div {...handlers}>
              <ContentsView
                containerRef={containerRef}
                c={c}
                currentContentId={currentContentId}
                purchasedContent={purchasedContent}
                setShowContentsModal={setShowContentsModal}
              />
            </div>
          )}
        </div>

        {showPaymentMethodModal === c.id && chapter && (
          <PaymentMethodModal
            contentId={c.id}
            title={c.title}
            thumbnailUrl={c.thumbnailUrl}
            chapter={chapter}
            setShownModal={setShowPaymentMethodModal}
          />
        )}

        {shownModalSignin === c.id && (
          <SigninModal
            setShownModalSignin={setShownModalSignin}
            shownModalSignin={shownModalSignin}
            chapter={chapter!.map((ch) => ch.chapter)}
          />
        )}
      </>
    );
  },
);
