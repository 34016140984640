import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  FirestoreDataConverter,
} from "firebase/firestore";
import type { VoiceDramaChapter } from "./type";

export const voiceDramaChapterConverter: FirestoreDataConverter<VoiceDramaChapter> =
  {
    toFirestore: (content: VoiceDramaChapter) => {
      return {
        totalTime: content.totalTime,
        price: content.price,
      };
    },
    fromFirestore: (
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions,
    ): VoiceDramaChapter => {
      const data = snapshot.data(options);
      return {
        totalTime: data.totalTime,
        price: data.price,
      };
    },
  };
