import { ContentsCollectionType } from "./../../../constants/features/voiceDrama/type";
import firestore, { Timestamp } from "firebase/firestore";

export const contentsCollectionConverter: firestore.FirestoreDataConverter<ContentsCollectionType> =
  {
    toFirestore(data: ContentsCollectionType): firestore.DocumentData {
      const convertData = {
        actors:
          data.actors.map((actor) => {
            return {
              name: actor.name,
              role: actor.role,
              image:
                actor.image instanceof File
                  ? actor.image.name.split(".")[0]
                  : actor.image,
            };
          }) || [],
        bgColor: data.bgColor,
        title: data.base.title,
        highlight: data.base.highlight,
        outline: data.base.outline,
        category: data.base.category,
        isPublished: data.base.publishedStatus === "PUBLISHED" ? true : false,
        createdAt: data.createdAt ?? Timestamp.now(),
        updatedAt: Timestamp.now(),
      };

      return convertData;
    },
    fromFirestore(snapshot, options): ContentsCollectionType {
      const data = snapshot.data(options);
      return {
        base: {
          documentId: snapshot.id,
          title: data.title,
          highlight: data.highlight,
          outline: data.outline,
          category: data.category,
          publishedStatus: data.isPublished ? "PUBLISHED" : "DRAFT",
        },
        actors: data.actors,
        bgColor: data.bgColor,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
      };
    },
  };
