import styles from "../../assets/styles/myContents.module.scss";
import { useGetOgpImageUrl } from "../../hooks/reactQuery/useGetOgpImageUrl";

import { Content } from "../../utils/types";
import { useNavigate } from "react-router-dom";

export const MyContentCard = ({ content }: { content: Content }) => {
  const navigate = useNavigate();
  const { data: ogpImageUrl } = useGetOgpImageUrl(content.id);

  return (
    <div
      className={styles.libraries_inner}
      onClick={() => {
        navigate(`?id=${content.id}&chapter=1&isPlaying=true`);
      }}
      style={{
        background: `linear-gradient(to bottom, ${content.bgColor.top} 0%, ${content.bgColor.bottom} 100%)`,
      }}
    >
      {ogpImageUrl && (
        <img
          src={ogpImageUrl}
          className={styles.thumbnail}
          alt={content.title}
        />
      )}
      <h2>{content.title}</h2>
      <ul className={styles.actor_name}>
        {content.actors.map((a, i) => (
          <li key={i}>{a.name}</li>
        ))}
      </ul>
    </div>
  );
};
