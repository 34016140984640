import { z as originalZod } from "zod";
import { VALIDATION_ERROR_MESSAGE } from "../../constants/error";

const dateTimeRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/; // YYYY-MM-DDThh:mm

const z = {
  ...originalZod,
  requiredString: () =>
    z
      .string({ required_error: VALIDATION_ERROR_MESSAGE.REQUIRED })
      .min(1, VALIDATION_ERROR_MESSAGE.REQUIRED),
  //   customEmail: () =>
  //     z.string().regex(
  //       // NOTE: https://www.w3.org/TR/2012/WD-html-markup-20121025/input.email.html#form.data.emailaddress_xref2
  //       /^(?:[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*)?$/,
  //       VALIDATION_ERROR_MESSAGE.EMAIL,
  //     ),
  // NOTE: https://docs.aws.amazon.com/ja_jp/cognito/latest/developerguide/user-pool-settings-policies.html
  // NOTE: https://regexr.com/7uqnt
  //   password: () =>
  //     z
  //       .string({ required_error: VALIDATION_ERROR_MESSAGE.REQUIRED })
  //       .regex(INPUT_PASSWORD_REGEX, VALIDATION_ERROR_MESSAGE.PASSWORD),
  optionalUrl: () =>
    z.union([
      z.string().url(VALIDATION_ERROR_MESSAGE.URL).nullish(),
      z.literal(""),
    ]),
  requiredDateTime: () =>
    z
      .string({ required_error: VALIDATION_ERROR_MESSAGE.REQUIRED })
      .regex(dateTimeRegex, VALIDATION_ERROR_MESSAGE.DATE_TIME)
      .min(1, VALIDATION_ERROR_MESSAGE.REQUIRED),
  optionalDateTime: () =>
    z
      .string({ required_error: VALIDATION_ERROR_MESSAGE.REQUIRED })
      .refine((val) => val === "" || dateTimeRegex.test(val), {
        message: VALIDATION_ERROR_MESSAGE.DATE_TIME,
      }),
  file: () => z.instanceof(File, { message: "ファイルを選択してください" }),
  requiredNumber: () =>
    z
      .requiredString()
      .refine((val) => !isNaN(Number(val)), "数値を入力してください")
      .refine((val) => Number.isInteger(Number(val)), "整数を入力してください")
      .refine((val) => Number(val) >= 0, "0以上の値を入力してください"),
};

export { z };
