import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../firebase";
import { useQuery } from "@tanstack/react-query";

export const useGetStorageUrl = (path: string | undefined) => {
  return useQuery({
    queryKey: ["storageMedia", path],
    enabled: !!path,
    queryFn: async () => {
      const storageMediaRef = ref(storage, path);
      return getDownloadURL(storageMediaRef);
    },
  });
};
