import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Contents from "./Contents";
import Interviews from "./interviews";

function Home() {
  const location = useLocation();

  return (
    <>
      <Header
        tab={[
          { name: "ボイスドラマ", url: "/contents" },
          { name: "インタビュー", url: "/interviews" },
        ]}
      />

      {location.pathname === "/interviews" ? <Interviews /> : <Contents />}

      <Nav />
    </>
  );
}

export default Home;
