import firestore, { Timestamp } from "firebase/firestore";
import { chaptersCollectionFormSchema } from "./../../../constants/features/voiceDrama/chapters";
import { ChapterCollectionType } from "../../../constants/features/voiceDrama/type";

// チャプター単体のconverter
export const chapterCollectionConverter: firestore.FirestoreDataConverter<ChapterCollectionType> =
  {
    toFirestore(data: ChapterCollectionType): firestore.DocumentData {
      return {
        price: Number(data.price),
        sampleTotalTime: Number(data.sampleTotalTime),
        totalTime: Number(data.totalTime),
        outline: data.outline,
        isPublished: data.publishedStatus === "PUBLISHED" ? true : false,
        actors:
          data.actors.map((actor) => {
            return {
              name: actor.name,
              role: actor.role,
              image:
                actor.image instanceof File
                  ? actor.image.name.split(".")[0]
                  : actor.image,
            };
          }) || [],
        createdAt: Timestamp.now(),
      };
    },
    fromFirestore(snapshot, options): ChapterCollectionType {
      const data = snapshot.data(options);
      return {
        price: data.price.toString(),
        sampleTotalTime: data.sampleTotalTime.toString(),
        totalTime: data.totalTime.toString(),
        outline: data.outline,
        publishedStatus: data.isPublished ? "PUBLISHED" : "DRAFT",
        actors: data.actors,
      };
    },
  };

// 複数のチャプターのconverter
export const chaptersCollectionConverter: firestore.FirestoreDataConverter<
  ChapterCollectionType[]
> = {
  toFirestore(chapters: ChapterCollectionType[]): firestore.DocumentData {
    return {
      chapters: chapters.map((chapter: ChapterCollectionType) =>
        chapterCollectionConverter.toFirestore(chapter),
      ),
    };
  },
  fromFirestore(snapshot, options): ChapterCollectionType[] {
    const data = snapshot.data(options);

    const chaptersArray = data.chapters || []; // `chapters`フィールドがない場合、空配列に設定

    const validatedData = chaptersCollectionFormSchema.parse(
      chaptersArray.map((chapterData: firestore.DocumentData) => {
        return {
          price: chapterData.price,
          sampleTotalTime: chapterData.sampleTotalTime,
          totalTime: chapterData.totalTime,
          outline: chapterData.outline,
          publishedStatus: chapterData.publishedStatus,
          actors: chapterData.actors,
        };
      }),
    );

    return validatedData;
  },
};
