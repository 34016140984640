import { InterviewFormData } from "../../../../../../constants/features/interview/interview";
import styles from "../../../../../../assets/styles/interview.module.scss";
import conversationStyle from "./style.module.css";
import { useFetchVoiceDramaDetail } from "../../../../../../hooks/admin/useFetchVoiceDramaDetail";
import { useEffect, useState } from "react";

export const Conversations = ({
  isLoggedIn,
  interviewId,
  conversations,
}: {
  isLoggedIn: boolean;
  interviewId: string | undefined;
  conversations: InterviewFormData["conversations"] | undefined;
}) => {
  const [bgColor, setBgColor] = useState<{ top: string; bottom: string }>();
  const { fetchVoiceDramaDetail } = useFetchVoiceDramaDetail(interviewId);

  const fetchData = async () => {
    const result = await fetchVoiceDramaDetail();
    if (result) {
      setBgColor({
        top: result?.bgColor.top,
        bottom: result?.bgColor.bottom,
      });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const interviewerStyle = {
    background: `linear-gradient(to right, 
      ${bgColor?.top} 0%,
      ${bgColor?.bottom} 100%
    )`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    color: bgColor?.top,
    fontWeight: 600,
    marginTop: "24px",
    display: "inline-block",
  };

  return (
    <>
      {conversations?.map((conversation, index) => {
        const isLastVisibleItem = index === 2 && !isLoggedIn;
        const truncateText = (text: string) => {
          if (text.length > 100) {
            return text.substring(0, 80) + "...";
          }
          return text;
        };

        if (index === 3 && !isLoggedIn) {
          return (
            <div key={index} className={styles.readmore}>
              <p>
                続きを読むには、
                <br />
                アカウント登録（無料）が必要です
              </p>
              <a
                href={`/signin?interViewId=${interviewId}`}
                className={`${styles.btn} ${styles.btnMedium} ${styles.btnPrimary}`}
              >
                アカウント登録 / ログイン
              </a>
            </div>
          );
        }

        if (index >= 3 && !isLoggedIn) {
          return null;
        }

        if (conversation.type === "text") {
          return (
            <div key={index}>
              <p style={interviewerStyle} className={styles.interviewer}>
                ―{conversation.interviewer}
              </p>
              <p className={isLastVisibleItem ? conversationStyle.fadeOut : ""}>
                {isLastVisibleItem
                  ? truncateText(conversation.interviewee)
                  : conversation.interviewee}
              </p>
            </div>
          );
        } else if (
          conversation.type === "image" &&
          typeof conversation.image === "string"
        ) {
          return (
            <div
              key={index}
              className={isLastVisibleItem ? styles.fadeOut : ""}
            >
              <img src={conversation.image} alt="" />
            </div>
          );
        }
      })}
    </>
  );
};
