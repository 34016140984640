import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { interviewConverter } from "../../interviews/interviewConverter";

export const useFetchInterviewDetail = (interviewId: string | undefined) => {
  const fetchInterviewDetail = async () => {
    if (!interviewId) return;
    try {
      const docRef = doc(db, "interviews", interviewId).withConverter(
        interviewConverter,
      );
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    } catch (error) {
      console.error(error);
    }
  };

  return { fetchInterviewDetail };
};
